<template>
    <div></div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        name: "MsgErro",
        computed: {
            ...mapGetters("msgErro", ['getMsgErro', 'getTxtMsgErro'])
        },
        data () {
            return {         
            } 
        },
        watch: {
            getMsgErro: function(val) {
                if (val) {
                    this.mensagem();
                    this.reiniciar();
                }
            }
        },
        methods: {
            ...mapActions('msgErro', {exibirMsgErro:'exibirMsgErro', reiniciar: 'reiniciar'}),
            mensagem() {
                this.$notify({
                    title: this.getTxtMsgErro,
                    icon: "ti-alert",
                    horizontalAlign: "right",
                    verticalAlign: "top",
                    type: "danger"
                });
            }
        }
}
</script>