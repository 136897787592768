

import {AXIOS} from '@/http-common';

const base = '/api/daysoff/';

export default {
    listarTodos : async function() {     
        return await AXIOS.get(`${base}listAll`);
    },
    salvar: async function(dados) {
        return await AXIOS.post(`${base}save`, dados);
    },
    excluir: async function(id) {
        return await AXIOS.get(`${base}delete/${id}`);
    }
}