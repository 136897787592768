import { AXIOS } from '@/http-common';

const base = '/api/routes';

export default {
  listar: async function() {
    return await AXIOS.get(`${base}`);
  },
  obter: async function(id) {
    return await AXIOS.get(`${base}/${id}`);
  },
  criar: async function(dados) {
    return await AXIOS.post(`${base}`, dados);
  },
  atualizar: async function(id, dados) {
    return await AXIOS.put(`${base}/${id}`, dados);
  },
  deletar: async function(id) {
    return await AXIOS.delete(`${base}/${id}`);
  }
};
