

import {AXIOS} from '@/http-common';

const base = '/api/';

export default {
    listar : async function(dados) {     
        return await AXIOS.post(`${base}listTicket`, dados);
    },
    listarTodos : async function(dados) {     
        return await AXIOS.post(`${base}listAllTicket`, dados);
    },
    exportarXls : async function(dados) {     
        return await AXIOS.post(`${base}exportXls`, dados);
    }
}