<template>
  <div>
      <div class="row">
        <div class="col-md-12">
          <h1>403 - Não Autorizado.</h1>
        </div>
      </div>
  </div>
</template>

<script>
export default {};
</script>
