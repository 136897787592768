import { AXIOS } from "@/http-common";
import api from "@/api/autenticacao.api";
import { CLIENT_ID, CLIENT_SECRET } from "@/config/api.js";  

const state = {
  usuario: {},
  existUsuarioLogado: false,
  token: null
};

const actions = {
  logout({ commit }) {
    commit("logout");
  },

  async autenticacao({ commit }, { email, senha }) {
    const dadosLogin = {
      grant_type: "password",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      username: email,
      password: senha,
      scope: ''
    };

    let retornoFinal = null;

    await api.login(dadosLogin)
      .then(async res => {
        const retorno = res.data;

        if (retorno.access_token) {
          const token = retorno.access_token;

          if (token != null && token != "") {
            AXIOS.defaults.headers.common["Authorization"] = token.includes(
              "Bearer"
            )
              ? token
              : "Bearer " + token;

            let usuario = await api.usuarioLogado();

            if (!usuario.data.allowed) {
              retornoFinal = false;
            } else {
              commit("autenticarUsuario", usuario.data);
              commit("setToken", retorno.access_token);
              retornoFinal = true;
            }
          }
        }
      })
      .catch(() => {
        retornoFinal = false;
      });

    return retornoFinal;
  }
};

const mutations = {
  autenticarUsuario(state, usuario) {
    state.usuario = usuario;
    state.existUsuarioLogado = true;
  },
  logout(state) {
    state.existUsuarioLogado = false;
    state.usuario = {};
    state.token = null;
    delete AXIOS.defaults.headers.common["Authorization"];
    location.reload();
  },
  setToken(state, token) {
    state.token = token;
  }
};

const getters = {
  usuarioLogado: state => state.usuario,
  existUsuarioLogado: state => state.existUsuarioLogado,
  token: state => state.token
};

export const autenticacao = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
