<template>
  <div>
    <h1>Gerenciamento de Rotas</h1>

    <!-- Botão para abrir o modal de adicionar/editar rota -->
    <button class="btn btn-primary mb-3" @click="openModal()">Adicionar Nova Rota</button>

    <!-- Modal para adicionar/editar uma rota -->
    <b-modal v-model="modalShow" title="Adicionar/Editar Rota" @hide="clearForm">
      <div class="form-group">
        <label>Partida:</label>
        <select v-model="routeForm.from_place_id" class="form-control" required>
          <option v-for="place in places" :key="place.id" :value="place.id">
            {{ place.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Destino:</label>
        <select v-model="routeForm.to_place_id" class="form-control" required>
          <option v-for="place in places" :key="place.id" :value="place.id">
            {{ place.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Preço:</label>
        <input 
          type="text" 
          v-model="routeForm.price" 
          required 
          v-money="money" 
          placeholder="Preço" 
        />
      </div>
      <div class="form-group">
        <label>Veículo:</label>
        <v-select
          :options="vehicles"
          v-model="routeForm.vehicle"
          placeholder="Selecione o veículo"
        />
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="btn btn-secondary" @click="cancel()">Cancelar</button>
        <button class="btn btn-primary" @click="saveRoute()">Salvar</button>
      </template>
    </b-modal>

    <!-- Tabela para listar todas as rotas -->
    <table class="table table-bordered mt-4">
      <thead>
        <tr>
          <th>ID</th>
          <th>Partida</th>
          <th>Destino</th>
          <th>Preço</th>
          <th>Veículo</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="route in routes" :key="route.id">
          <td>{{ route.id }}</td>
          <td>{{ route.fromPlace.name }}</td>
          <td>{{ route.toPlace.name }}</td>
          <td>{{ formatPrice(route.price) }}</td>
          <td>{{ route.vehicle }}</td>
          <td>
            <button class="btn btn-info" @click="editRoute(route)">Editar</button>
            <button class="btn btn-danger" @click="deleteRoute(route.id)">Excluir</button>
          </td>
        </tr>
        <tr v-if="routes.length === 0">
          <td colspan="6" class="text-center">Nenhum registro encontrado.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from '@/api/route.api.js';
import placeApi from '@/api/place.api.js';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import money, { VMoney } from 'v-money';

export default {
  components: {
    vSelect
  },
  directives: {
    money: VMoney
  },
  data() {
    return {
      routes: [],
      places: [],
      vehicles: [
        { label: 'Carro', value: 'Carro' },
        { label: 'Micro-ônibus', value: 'Micro-ônibus' },
        { label: 'Ônibus', value: 'Ônibus' }
      ],
      routeForm: {
        id: null,
        from_place_id: '',
        to_place_id: '',
        price: '',
        vehicle: ''
      },
      modalShow: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      }
    };
  },
  methods: {
    async fetchRoutes() {
      try {
        const response = await api.listar();
        this.routes = response.data;
      } catch (error) {
        console.error('Erro ao buscar rotas:', error);
      }
    },
    async fetchPlaces() {
      try {
        const response = await placeApi.listar();
        this.places = response.data;
      } catch (error) {
        console.error('Erro ao buscar lugares:', error);
      }
    },
    async saveRoute() {
      try {
        if (this.routeForm.id) {
          // Editar rota existente
          await api.atualizar(this.routeForm.id, this.routeForm);
        } else {
          // Criar nova rota
          await api.criar(this.routeForm);
        }
        this.fetchRoutes();
        this.clearForm();
        this.modalShow = false;
      } catch (error) {
        console.error('Erro ao salvar rota:', error);
      }
    },
    editRoute(route) {
      this.routeForm = { ...route };
      this.modalShow = true;
    },
    async deleteRoute(id) {
      try {
        await api.deletar(id);
        this.fetchRoutes();
      } catch (error) {
        console.error('Erro ao excluir rota:', error);
      }
    },
    clearForm() {
      this.routeForm = {
        id: null,
        from_place_id: '',
        to_place_id: '',
        price: '',
        vehicle: ''
      };
    },
    openModal() {
      this.clearForm();
      this.modalShow = true;
    },
    formatPrice(price) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
    }
  },
  mounted() {
    this.fetchRoutes();
    this.fetchPlaces();
  }
};
</script>

<style scoped>
.table {
  margin-top: 20px;
}
</style>
