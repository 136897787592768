<template>
    <div>
      <h1>Gerenciamento de Lugares</h1>

      <!-- Botão para abrir o modal de adicionar/editar lugar -->
      <button class="btn btn-primary mb-3" @click="openModal()">Adicionar Novo Lugar</button>

      <!-- Modal para adicionar/editar um lugar -->
      <b-modal v-model="modalShow" title="Adicionar/Editar Lugar" @hide="clearForm">
        <div class="form-group">
          <label>Nome:</label>
          <input type="text" v-model="placeForm.name" class="form-control" required />
        </div>
        <div class="form-group">
          <label>Tipo:</label>
          <v-select
            :options="placeTypes"
            v-model="placeForm.type"
            placeholder="Selecione o tipo"
          />
        </div>
        <template #modal-footer="{ ok, cancel }">
          <button class="btn btn-secondary" @click="cancel()">Cancelar</button>
          <button class="btn btn-primary" @click="savePlace()">Salvar</button>
        </template>
      </b-modal>

      <!-- Tabela para listar todos os lugares -->
      <table class="table table-bordered mt-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Tipo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="place in places" :key="place.id">
            <td>{{ place.id }}</td>
            <td>{{ place.name }}</td>
            <td>{{ place.type }}</td>
            <td>
              <button class="btn btn-info" @click="editPlace(place)">Editar</button> 
              <button class="btn btn-danger" @click="deletePlace(place.id)">Excluir</button>
            </td>
          </tr>
          <tr v-if="places.length === 0">
            <td colspan="4" class="text-center">Nenhum registro encontrado.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import api from '@/api/place.api.js';
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  
  export default {
    components: {
      vSelect
    },
    data() {
      return {
        places: [],
        placeForm: {
          id: null,
          name: '',
          type: ''
        },
        placeTypes: [
          { label: 'Aeroporto', value: 'aeroporto' },
          { label: 'Hotel', value: 'hotel' },
          { label: 'Porto', value: 'porto' },
          { label: 'Outro', value: 'outro' }
        ],
        modalShow: false
      };
    },
    methods: {
      async fetchPlaces() {
        try {
          const response = await api.listar();
          this.places = response.data;
        } catch (error) {
          console.error('Erro ao buscar lugares:', error);
        }
      },
      async savePlace() {
        try {
          if (this.placeForm.id) {
            // Editar lugar existente
            await api.atualizar(this.placeForm.id, this.placeForm);
          } else {
            // Criar novo lugar
            await api.criar(this.placeForm);
          }
          this.fetchPlaces();
          this.clearForm();
          this.modalShow = false;
        } catch (error) {
          console.error('Erro ao salvar lugar:', error);
        }
      },
      editPlace(place) {
        this.placeForm = { ...place };
        this.modalShow = true;
      },
      async deletePlace(id) {
        try {
          await api.deletar(id);
          this.fetchPlaces();
        } catch (error) {
          console.error('Erro ao excluir lugar:', error);
        }
      },
      clearForm() {
        this.placeForm = {
          id: null,
          name: '',
          type: ''
        };
      },
      openModal() {
        this.clearForm();
        this.modalShow = true;
      }
    },
    mounted() {
      this.fetchPlaces();
    }
  };
  </script>
  
  <style scoped>
  .table {
    margin-top: 20px;
  }
  </style>
  