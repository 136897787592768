const state = {
  exibirMsgSucesso: false,
  txtMsgSucesso: '',
};

const actions = {
  exibirMsgSucesso({ commit }, msg) {
    commit("definirMsg", msg && msg !== undefined ? msg : "Operação realizada com sucesso!");
    commit("trocarEstado", true);
  },
  reiniciar({ commit }) {
    commit("trocarEstado", false);
  }
};

const getters = {
  getMsgSucesso: state => {
    return state.exibirMsgSucesso;
  },
  getTxtMsgSucesso: state => {
    return state.txtMsgSucesso;
  }
};

const mutations = {
  trocarEstado(state, estado) {
    state.exibirMsgSucesso = estado;
  },
  definirMsg(state, msg) {
    state.txtMsgSucesso = msg;
  }
};

export const msgSucesso = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
