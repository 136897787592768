import axios from "axios";
import store from "@/store";

var paramsAxios = {
  baseURL: "https://api.consultorahelena.com.br/",
  headers: {
    "Content-Type": "application/json"
  }
};

if (store !== undefined && store.getters["autenticacao/token"]) {
  paramsAxios["headers"][
    "Authorization"
  ] = `Bearer ${store.getters["autenticacao/token"]}`;
}

let api = axios.create(paramsAxios);

const errorHandler = async error => {
  store._actions["loader/loading"][0](false);
  const e = {
    responseText: error.request.responseText,
    responseURL: error.request.responseURL,
    status: error.request.status,
    statusText: error.request.statusText,
    requestwithCredentials: error.request.withCredentials
  };

  if (
    e.status === 403
  ) {
    await store._actions["autenticacao/logout"][0]();
  } else {
    if(e.status !== 401) store._actions["msgErro/exibirMsgErro"][0]();

    return Promise.reject(e);
  }
};

const successHandler = response => {
  store._actions["loader/loading"][0](false);
  return response;
};

api.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

api.interceptors.request.use(
  config => {
    store._actions["loader/loading"][0](true);
    return config;
  },
  error => {
    store._actions["loader/loading"][0](false);
    return Promise.reject(error);
  }
);

export const AXIOS = api;