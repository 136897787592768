import DefaultLayout from "@/layout/Default.vue";

import LoginLayout from "@/layout/Login.vue";

import Page404 from "@/pages/404.vue";
import Page403 from "@/pages/403.vue";

import Places from "@/pages/Places.vue";
import Routes from "@/pages/Routes.vue";
import Tickets from "@/pages/Tickets.vue";
import Agenda from "@/pages/Agenda.vue";
import Login from "@/pages/Login.vue";
import Usuarios from "@/pages/Usuarios.vue";

import store from "@/store";

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    redirect: "/tickets",
    children: [
      {
        path: "places",
        name: "locais",
        component: Places,
        beforeEnter: requerAutenticacao
      },
      {
        path: "routes",
        name: "rotas",
        component: Routes,
        beforeEnter: requerAutenticacao
      },
      {
        path: "tickets",
        name: "tickets",
        component: Tickets,
        beforeEnter: requerAutenticacao
      },
      {
        path: "agenda",
        name: "agenda",
        component: Agenda,
        beforeEnter: requerAutenticacao
      },
      {
        path: "login",
        name: "login",
        component: Login,
        beforeEnter: requerAutenticacao
      },
      {
        path: "usuarios",
        name: "usuarios",
        component: Usuarios,
        beforeEnter: requerAutenticacao
      },
      {
        path: "page403",
        name: "page403",
        component: Page403
      }
    ]
  },
  {
    path: "/autenticacao",
    component: LoginLayout,
    redirect: "/login",
    children: [
      {
        path: "login",
        name: "login",
        component: Login,
        beforeEnter: poossuiUsuarioLogado
      }
    ]
  },
  { path: "*", component: Page404 }
];

export default routes;

// Verificações para saber se o usuario esta perfeitamente logado no sistema
function verificarUsuarioLogado() {
  const existUsuarioLogado = store.getters["autenticacao/existUsuarioLogado"];
  const usuarioLogado = store.getters["autenticacao/usuarioLogado"];
  const token = store.getters["autenticacao/token"];
  return existUsuarioLogado && usuarioLogado["id"] !== "" && token;
}

// Verifica se o usuario que ja esta logado, esta tentando acessar a pagina de login novamente
function poossuiUsuarioLogado(to, from, next) {
  async function proceed() {
    if (verificarUsuarioLogado() && to.path === "/autenticacao/login") {
      next(from);
      return;
    }
    next();
  }
  proceed();
}

function requerAutenticacao(to, from, next) {
  // Função para permitir o bloquear o fluxo da rota
  async function proceed() {
    // Verifica se o usuario esta logado, senao envia ele pro login
    if (!verificarUsuarioLogado()) {
      next("/autenticacao/login");
      return;
    }
    next();
  }
  // Efetiva a chamada do fluxo
  proceed();
}