<template>
  <div id="login">
    <page-content></page-content>
  </div>
</template>
<script>
  import PageContent from "./Content.vue";
  export default {
    components: {
      PageContent
    },
    methods: {
    }
  };
</script>
<style>
  body {
    background: #666666 !important;
  }
  #login {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
</style>