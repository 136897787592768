const state = {
  isLoading: false
};

const actions = {
  loading({ commit }, estado) {
    commit("trocarEstado", estado);
  }
};

const getters = {
  getLoading: state => {
    return state.isLoading;
  }
};

const mutations = {
  trocarEstado(state, estado) {
    state.isLoading = estado;
  }
};

export const loader = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
