<template>
  <div>
    <!-- Restante do código -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12 text-right">
                <b-button
                  v-b-tooltip.hover
                  title="Filtrar"
                  class="btn btn-info mr-1"
                  @click="exibirFiltro"
                >
                  <i class="ti-search"></i> Filtrar
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  title="Novo Dia Indisponível"
                  class="btn btn-default"
                  @click="dayoff_abrirNovo"
                >
                  <i class="ti-plus"></i> Novo Dia Indisponível
                </b-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <FullCalendar :options="calendarOptions" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Restante do código -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import api from '@/api/ticket.api.js';
import apiDayOff from '@/api/dayoff.api.js';
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptbrLocale from '@fullcalendar/core/locales/pt-br';
import { Tooltip } from "bootstrap";

export default {
  components: {
    FullCalendar
  },
  data() {
    return {
      filtrar: false,
      filtro: {
        nome: null,
        email: null,
        status: null,
        created_at: null,
        service_date: null,
        vehicle: null,
        start_address: null,
        end_address: null
      },
      lista: [],
      listaStatus: [
        { label: "Pendente", code: 0 },
        { label: "Aprovado", code: 2 },
        { label: "Reprovado", code: 3 },
      ],
      listaVeiculos: [
        { label: "Carro", code: 1 },
        { label: "Van", code: 2 }
      ],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        eventDidMount: this.eventDidMount,
        initialView: 'dayGridMonth',
        events: [],
        locale: ptbrLocale
      },
      dados: {
        name: null,
        email: null,
        phone: null,
        travel_price: null,
        sale_status: null,
        date_checkout_br: null,
        start_address: null,
        end_address: null,
        is_round_trip: null,
        service_date_br: null,
        service_date_round_br: null,
        vehicle: null,
        lang: null,
        created_at: null,
        updated_at: null
      },
      modalShow: false,
      dayoff: {
        id: null,
        description: null,
        date: null
      },
      modalShowDayOff: false,
      msgConfirmacao: "Tem certeza que deseja realizar esta operação?",
      msgErroFormulario: "Erros no preenchimento do formulário."
    };
  },
  validations: {
    dayoff: {
      description: { required },
      date: { required }
    }
  },
  mounted() {
    this.obterLista();
  },
  methods: {
    ...mapActions('msgErro', { exibirMsgErro: 'exibirMsgErro' }),
    ...mapActions('msgSucesso', { exibirMsgSucesso: 'exibirMsgSucesso' }),
    exibirFiltro() {
      this.filtrar = !this.filtrar;
    },
    async obterLista() {
      await api.listarTodos({
        name: this.filtro.nome,
        email: this.filtro.email,
        sale_status: this.filtro.status ? this.filtro.status.code : null,
        created_at: this.filtro.created_at,
        service_date: this.filtro.service_date,
        vehicle: this.filtro.vehicle ? this.filtro.vehicle.code : null,
        start_address: this.filtro.start_address,
        end_address: this.filtro.end_address
      }).then(res => {
        this.calendarOptions.events = res.data.data.map((item) => {
          return {
            title: item.fromPlace + ' p/ ' + item.toPlace,
            date: item.service_date,
            extendedProps: item
          };
        });
        this.total = res.data.total;
      });

      await apiDayOff.listarTodos().then(res => {
        this.calendarOptions.events = [
          ...this.calendarOptions.events,
          ...res.data.data.map((item) => {
            return {
              title: item.description,
              date: item.date,
              extendedProps: item,
              backgroundColor: 'orange'
            };
          })
        ];
      });
    },
    exportarXls() {
      api.exportarXls({
        name: this.filtro.nome,
        email: this.filtro.email,
        sale_status: this.filtro.status ? this.filtro.status.code : null,
        created_at: this.filtro.created_at,
        service_date: this.filtro.service_date,
        vehicle: this.filtro.vehicle ? this.filtro.vehicle.code : null,
        start_address: this.filtro.start_address,
        end_address: this.filtro.end_address
      }).then(res => {
        var a = document.createElement("a");
        a.href = res.data.file;
        a.download = res.data.name;
        a.click();
      });
    },
    handleDateClick(arg) {
    },
    handleEventClick(arg) {
      if (arg.event.extendedProps.date === undefined) {
        this.modalShow = true;
        this.dados = arg.event.extendedProps;
      } else {
        this.modalShowDayOff = true;
        this.dayoff = {
          description: null,
          date: null
        };
        this.dayoff = Object.assign({}, arg.event.extendedProps);
      }
    },
    eventDidMount(info) {
      const title = info.event.extendedProps.description || "Sem descrição";
      var tooltip = new Tooltip(info.el, {
        title: title,
        placement: 'top',
        trigger: 'hover',
        container: 'body'
      });
    },
    dayoff_abrirNovo() {
      this.dayoff = {
        description: null,
        date: null
      };
      this.$v.$reset();
      this.modalShowDayOff = true;
    },
    dayoff_salvar() {
      this.$v.$touch();
      if (this.$v.dayoff.$invalid) {
        this.exibirMsgErro(this.msgErroFormulario);
      } else {
        apiDayOff.salvar(this.dayoff).then(() => {
          this.obterLista();
          this.modalShowDayOff = false;
          this.exibirMsgSucesso();
        });
      }
    },
    dayoff_excluir() {
      const dados = Object.assign({}, this.dayoff);
      this.$bvModal.msgBoxConfirm(this.msgConfirmacao, {
        title: 'Por favor, confirme!',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirmar',
        cancelTitle: 'Cancelar',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          apiDayOff.excluir(dados.id).then(() => {
            this.exibirMsgSucesso();
            this.obterLista();
            this.modalShowDayOff = false;
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.vue-select3 {
  width: 100%;
}
</style>
