import Vue from "vue";
import store from "@/store";
import App from "./App";
import router from "./router/index";

// escopo tema
import PaperDashboard from "./plugins/paperDashboard";
Vue.use(PaperDashboard);

import Notify from 'vue-notifyjs';
import "vue-notifyjs/themes/default.css";
Vue.use(Notify);

// bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// dados falso para exemplo
Vue.use(require("vue-faker"), { locale: "pt_BR" });

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import vSelect from 'vue-select'
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

import VueMask from 'v-mask';
Vue.use(VueMask);

import money from 'v-money'
 
// register directive v-money and component <money>
Vue.use(money, {precision: 4})

/* eslint-disable no-new */
const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

export default vm;
