<template>
  <div>
      <div>
        <div class="row" v-if="filtrar">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4>Filtro de pesquisa</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Nome</label>
                      <b-form-input
                          type="text"
                          class="form-control"
                          placeholder="Nome"
                          maxlength="255"
                          v-model.trim="filtro.name"
                          ></b-form-input>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>E-mail</label>
                      <b-form-input
                          type="text"
                          class="form-control"
                          placeholder="Descrição"
                          maxlength="255"
                          v-model.trim="filtro.email"
                          ></b-form-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-md-12">
                    <b-button
                      v-b-tooltip.hover
                      title="Filtrar"
                      class="btn btn-default pull-right"
                      @click="obterLista">
                        <i class="ti-search"></i> Filtrar
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-12 text-right">
                    <b-button
                      v-b-tooltip.hover
                      title="Casdastrar nova"
                      class="btn btn-success mr-1"
                      @click="abrirNovo">
                        <i class="ti-plus"></i> Novo
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Filtrar"
                      class="btn btn-info mr-1"
                      @click="exibirFiltro"
                      :disabled="lista.length === 0">
                        <i class="ti-search"></i> Filtrar
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Alterar"
                      class="btn btn-info mr-1"
                      :disabled="selecionados.length === 0"
                      @click="abrirAlteracao">
                        <i class="ti-pencil"></i> Alterar
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Alterar"
                      class="btn btn-info mr-1"
                      :disabled="selecionados.length === 0"
                      @click="abrirAlteracaoSenha">
                        <i class="ti-key"></i> Alterar Senha
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Desbloquear"
                      class="btn btn-success mr-1"
                      :disabled="selecionados.length === 0"
                      @click="desbloquear">
                        <i class="ti-check"></i> Desbloquear Acesso
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Excluir"
                      class="btn btn-danger"
                      :disabled="selecionados.length === 0"
                      @click="bloquear">
                        <i class="ti-na"></i> Bloquear Acesso
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div v-if="lista.length" class="table-responsive">
                      <b-table
                        id="registros"
                        :fields="colunas"
                        :items="lista"
                        :per-page="porPagina"
                        sticky-header
                        head-variant="dark"
                        striped
                        hover
                        selectable
                        :select-mode="selectMode"
                        @row-selected="selecionar"
                        @sort-changed="definirOrdem"
                        :sort-by="ordem"
                        style="max-height: 600px">
                          <template #cell(selecionado)="{ rowSelected }">
                            <template v-if="rowSelected">
                              <i class="ti-check green"></i>
                              <span class="sr-only">Selecionado</span>
                            </template>
                            <template v-else>
                              <span class="sr-only">Não selecionado</span>
                            </template>
                          </template>
                          <template #cell(allowed)="{item}">
                            <template>
                              {{ item.allowed ? 'SIM' : 'NÃO' }}
                            </template>
                          </template>
                          <template #cell(admin)="{item}">
                            <template>
                              {{ item.admin ? 'SIM' : 'NÃO' }}
                            </template>
                          </template>
                      </b-table>
                    </div>
                    <div v-if="lista.length === 0">
                      <p>Nenhum registro encontrado.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="lista.length" class="card-footer">
                <div class="row">
                  <div class="col-xs-12 col-md-4">
                    <div v-if="lista.length" class="row">
                      <div class="col-md-12">
                      <b-pagination
                          :total-rows="total"
                          :per-page="porPagina"
                          @change="obterLista"
                          v-model="paginaAtual"
                      ></b-pagination>
                      <span class="mr-1">Montrando {{ lista.length }} de {{ porPagina }} em {{ porPagina }} de um total de {{ total }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <b-button-group class="mr-1">
                      <b-dropdown left variant="default" text="Quantidade">
                        <b-dropdown-item
                          @click="porPagina = 10; obterLista(0)">10
                        </b-dropdown-item>

                        <b-dropdown-item
                          @click="porPagina = 50; obterLista(0)">50
                        </b-dropdown-item>

                        <b-dropdown-item
                          @click="porPagina = 100; obterLista(0)">100
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-button-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        v-model="modalShowAdd">
          <div class="form-group" :class="{ 'form-group--error': $v.dados.name.$error }">
            <label>Nome</label>
            <b-form-input
                type="text"
                class="form-control"
                placeholder="Nome"
                maxlength="255"
                v-model.trim="$v.dados.name.$model"></b-form-input>
          </div>

          <div class="form-group" :class="{ 'form-group--error': $v.dados.email.$error }">
            <label>E-mail</label>
            <b-form-input
                type="text"
                class="form-control"
                placeholder="E-mail"
                maxlength="255"
                v-model.trim="$v.dados.email.$model"></b-form-input>
          </div>

          <div class="form-group" :class="{ 'form-group--error': $v.dados.password.$error }">
            <label>Senha</label>
            <b-form-input
                type="password"
                class="form-control"
                placeholder="Senha"
                maxlength="255"
                v-model.trim="$v.dados.password.$model"></b-form-input>
          </div>

          <div class="form-group" :class="{ 'form-group--error': $v.dados.password_confirm.$error }">
            <label>Confirmar Senha</label>
            <b-form-input
                type="password"
                class="form-control"
                placeholder="Confirmar Senha"
                maxlength="255"
                v-model.trim="$v.dados.password_confirm.$model"></b-form-input>
          </div>

          <b-form-group label="Administrador" v-slot="{ admin }">
              <b-form-radio v-model="$v.dados.admin.$model" :aria-describedby="admin" name="some-radios" value=0>Não</b-form-radio>
              <b-form-radio v-model="$v.dados.admin.$model" :aria-describedby="admin" name="some-radios" value=1>Sim</b-form-radio>
          </b-form-group>

          <template #modal-footer="{ cancel }">
            <b-button size="sm" variant="default" @click="cancel()">
              Cancelar
            </b-button>
            <b-button size="sm" variant="success" @click="salvar()">
              Salvar
            </b-button>
          </template>
      </b-modal>

      <b-modal
        v-model="modalShowEdit">
          <div class="form-group" :class="{ 'form-group--error': $v.dadosEdit.name.$error }">
            <label>Nome</label>
            <b-form-input
                type="text"
                class="form-control"
                placeholder="Nome"
                maxlength="255"
                v-model.trim="$v.dadosEdit.name.$model"></b-form-input>
          </div>

          <div class="form-group" :class="{ 'form-group--error': $v.dadosEdit.email.$error }">
            <label>E-mail</label>
            <b-form-input
                type="text"
                class="form-control"
                placeholder="E-mail"
                maxlength="255"
                v-model.trim="$v.dadosEdit.email.$model"></b-form-input>
          </div>

          <b-form-group label="Administrador" v-slot="{ admin }">
              <b-form-radio v-model="$v.dadosEdit.admin.$model" :aria-describedby="admin" name="some-radios" value=0>Não</b-form-radio>
              <b-form-radio v-model="$v.dadosEdit.admin.$model" :aria-describedby="admin" name="some-radios" value=1>Sim</b-form-radio>
          </b-form-group>

          <template #modal-footer="{ cancel }">
            <b-button size="sm" variant="default" @click="cancel()">
              Cancelar
            </b-button>
            <b-button size="sm" variant="success" @click="salvarEdicao()">
              Salvar
            </b-button>
          </template>
      </b-modal>

      <b-modal
        v-model="modalShowAlterarSenha">
          <div class="form-group" :class="{ 'form-group--error': $v.dadosAlterarSenha.password.$error }">
            <label>Senha</label>
            <b-form-input
                type="password"
                class="form-control"
                placeholder="Senha"
                maxlength="255"
                v-model.trim="$v.dadosAlterarSenha.password.$model"></b-form-input>
          </div>

          <div class="form-group" :class="{ 'form-group--error': $v.dadosAlterarSenha.password_confirm.$error }">
            <label>Confirmar Senha</label>
            <b-form-input
                type="password"
                class="form-control"
                placeholder="Confirmar Senha"
                maxlength="255"
                v-model.trim="$v.dadosAlterarSenha.password_confirm.$model"></b-form-input>
          </div>

          <template #modal-footer="{ cancel }">
            <b-button size="sm" variant="default" @click="cancel()">
              Cancelar
            </b-button>
            <b-button size="sm" variant="success" @click="salvarSenha()">
              Salvar
            </b-button>
          </template>
      </b-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import { required, email, sameAs } from 'vuelidate/lib/validators';

import api from '@/api/usuario.api.js';

export default {
  components: {
  },
  data() {
    return {
      registroAtual: null,
      filtrar: false,
      filtro: {
        name: '',
        email: '',
      },
      dados: {
        name: '',
        email: '',
        password: '',
        password_confirm: ''
      },
      dadosEdit: {
        name: '',
        email: ''
      },
      dadosAlterarSenha: {
        id: null,
        password: '',
        password_confirm: ''
      },
      modalShowAdd: false,
      modalShowEdit: false,
      modalShowAlterarSenha: false,
      colunas: [
          {
            key: 'selecionado',
            label: 'Selecionado'
          },
          {
            key: 'name',
            label: 'Nome',
            sortable: true
          },
          {
            key: 'email',
            label: 'E-mail',
            sortable: true
          },
          {
            key: 'allowed',
            label: 'Acesso permitido',
            sortable: true
          },
          {
            key: 'created_at',
            label: 'Data criação',
            sortable: true
          },
          {
            key: 'updated_at',
            label: 'Data de modificação',
            sortable: true
          }
        ],
        lista: [],
        selectMode: 'single',
        selecionados: [],
        paginaAtual: 1,
        total: 0,
        porPagina: 10,
        ordem: 'created_at',
        tp_ordem: true,
        msgConfirmacao: "Tem certeza que deseja realizar esta operação?",
        msgErroFormulario: "Erros no preenchimento do formulário."
    };
  },
  validations: {
    dados: {
      name: { required },
      email: { required, email },
      password: { required },
      password_confirm: { required, sameAsPassword: sameAs('password') },
      admin: { required: false }
    },
    dadosEdit: {
      name: { required },
      email: { required, email },
      admin: { required: false }
    },
    dadosAlterarSenha: {
      password: { required },
      password_confirm: { required, sameAsPassword: sameAs('password') },
    }
  },
  mounted() {
    this.obterLista(0);
  },
  computed: {
  },
  methods: {
    ...mapActions('msgErro', {exibirMsgErro:'exibirMsgErro'}),
    ...mapActions('msgSucesso', {exibirMsgSucesso: 'exibirMsgSucesso'}),
    exibirFiltro() {
      this.filtrar = !this.filtrar;
    },
    salvar() {
      this.$v.$touch();
      if (this.$v.dados.$invalid) {
        this.exibirMsgErro(this.msgErroFormulario);
      } else {
        api.salvar(this.dados)
          .then(() => {
            this.obterLista();
            this.modalShowAdd = false;
            this.exibirMsgSucesso();
          });
      }
    },
    salvarEdicao() {
      this.$v.$touch();
      if (this.$v.dadosEdit.$invalid) {
        this.exibirMsgErro(this.msgErroFormulario);
      } else {
        api.salvar(this.dadosEdit)
          .then(() => {
            this.obterLista();
            this.modalShowEdit = false;
            this.exibirMsgSucesso();
          });
      }
    },
    definirOrdem(e) {
      this.ordem = e.sortBy;
      this.tp_ordem = e.sortDesc;
      this.obterLista(0);
    },
    obterLista(paginaAtual) {
      const pag = ((paginaAtual !== undefined ? paginaAtual : this.paginaAtual) - 1);

      api.listar({
        offset: pag < 0 ? 0 : pag,
        limit: this.porPagina,
        ordem: this.ordem,
        tp_ordem: this.tp_ordem ? 'DESC' : 'ASC',
        name: this.filtro.name,
        email: this.filtro.email
      }).then(res => {
        this.lista = res.data.data;
        this.total = res.data.total;
      });
    },
    selecionar(itens) {
      this.selecionados = itens;
    },
    abrirNovo() {
      this.dados.id = null;
      this.dados.name = '';
      this.dados.password = '';
      this.dados.password_confirm = '';
      this.modalShowAdd = true;
      this.$v.$reset();
    },
    async abrirAlteracao() {
      this.$v.$reset();

      this.modalShowEdit = true;

      const dados = Object.assign({}, this.selecionados[0]);

      this.dadosEdit = dados;
    },
    abrirAlteracaoSenha() {
      const dados = Object.assign({}, this.selecionados[0]);
      this.dadosAlterarSenha = {
        id: dados.id,
        password: '',
        password_confirm: '',
      };
      this.modalShowAlterarSenha = true;
      this.$v.$reset();
    },
    salvarSenha() {
      this.$v.$touch();
      if (this.$v.dadosAlterarSenha.$invalid) {
        this.exibirMsgErro(this.msgErroFormulario);
      } else {
        api.salvar(this.dadosAlterarSenha)
          .then(() => {
            this.obterLista();
            this.modalShowAlterarSenha = false;
            this.exibirMsgSucesso();
          });
      }
    },
    desbloquear() {
      const dados = Object.assign({}, this.selecionados[0]);

      this.$bvModal.msgBoxConfirm(this.msgConfirmacao, {
          title: 'Por favor, confirme!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirmar',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if (value) {
              api.ativar(dados.id).then(() => {
                this.exibirMsgSucesso();
                this.obterLista();
                this.selecionados = [];
              });
            }
          });
    },
    bloquear() {
      const dados = Object.assign({}, this.selecionados[0]);

      this.$bvModal.msgBoxConfirm(this.msgConfirmacao, {
          title: 'Por favor, confirme!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirmar',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if (value) {
              api.inativar(dados.id).then(() => {
                this.exibirMsgSucesso();
                this.obterLista();
                this.selecionados = [];
              });
            }
          });
    }
  }
};
</script>
<style>
</style>