<template>
  <div>
    <div>
      <div class="row" v-if="filtrar">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="mt-0">Filtro de pesquisa</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Nome</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Nome"
                      maxlength="255"
                      v-model.trim="filtro.nome"
                    ></b-form-input>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>E-mail</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="E-mail"
                      maxlength="255"
                      v-model.trim="filtro.email"
                    ></b-form-input>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Status</label>
                    <v-select
                      class="vue-select3"
                      :options="listaStatus"
                      placeholder="Selecione ..."
                      v-model="filtro.status"
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 text-right">
                  <b-button
                    v-b-tooltip.hover
                    title="Filtrar"
                    class="btn btn-default mr-1"
                    @click="exportarXls"
                  >
                    <i class="ti-export"></i> Exportar
                  </b-button>

                  <b-button
                    v-b-tooltip.hover
                    title="Filtrar"
                    class="btn btn-default"
                    @click="obterLista"
                  >
                    <i class="ti-search"></i> Filtrar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-12 text-right">
                  <b-button
                    v-b-tooltip.hover
                    title="Filtrar"
                    class="btn btn-info mr-1"
                    @click="exibirFiltro"
                  >
                    <i class="ti-search"></i> Filtrar
                  </b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div
                    v-if="lista.length"
                    class="table-responsive"
                    style="font-size: smaller"
                  >
                    <b-table
                      id="registros"
                      :fields="colunas"
                      :items="lista"
                      :per-page="porPagina"
                      sticky-header
                      small
                      striped
                      hover
                      head-variant="dark"
                      responsive="sm"
                      :select-mode="selectMode"
                      @sort-changed="definirOrdem"
                      :sort-by="ordem"
                      style="max-height: 600px"
                    >
                      <template #cell(sale_status)="{ item }">
                        <template>
                          <span class="badge badge-default" v-if="item.sale_status == 0">Pagamento pendente</span>
                          <span class="badge badge-success" v-if="item.sale_status == 2">Pagamento aprovado</span>
                          <span class="badge badge-danger" v-if="item.sale_status == 3">Pagamento recusado</span>
                        </template>
                      </template>
                    </b-table>
                  </div>
                  <div v-if="lista.length === 0">
                    <p>Nenhum registro encontrado.</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="lista.length" class="card-footer">
              <div class="row">
                <div class="col-xs-12 col-md-4">
                  <div v-if="lista.length" class="row">
                    <div class="col-md-12">
                      <b-pagination
                        :total-rows="total"
                        :per-page="porPagina"
                        @change="obterLista"
                        v-model="paginaAtual"
                      ></b-pagination>
                      <span class="mr-1">Montrando {{ lista.length }} de {{ porPagina }} em {{ porPagina }} de um total de {{ total }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4">
                  <b-button-group class="mr-1">
                    <b-dropdown left variant="default" text="Quantidade">
                      <b-dropdown-item
                        @click="porPagina = 10; obterLista(0)">10
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="porPagina = 50; obterLista(0)">50
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="porPagina = 100; obterLista(0)">100
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/api/ticket.api.js';

export default {
  data() {
    return {
      filtrar: false,
      filtro: {
        nome: null,
        email: null,
        status: null,
      },
      listaStatus: [
        { label: "Pendente", code: 0 },
        { label: "Aprovado", code: 2 },
        { label: "Reprovado", code: 3 },
      ],
      colunas: [
        { key: 'name', label: 'Nome', sortable: true },
        { key: 'email', label: 'E-mail', sortable: true },
        { key: 'phone', label: 'Telefone', sortable: true },
        { key: 'travel_price', label: 'Preço', sortable: true },
        { key: 'sale_status', label: 'Status', sortable: true },
        { key: 'date_checkout_br', label: 'Data pagamento', sortable: true },
        { key: 'fromPlace', label: 'Origem', sortable: true },
        { key: 'toPlace', label: 'Destino', sortable: true },
        { key: 'is_round_trip', label: 'Ida e volta', sortable: true },
        { key: 'service_date_br', label: 'Data serviço', sortable: true },
        { key: 'service_date_round_br', label: 'Data serviço volta', sortable: true },
        { key: 'vehicle', label: 'Veículo', sortable: true },
        { key: 'lang', label: 'Idioma', sortable: true },
        { key: 'created_at', label: 'Data criação', sortable: true },
        { key: 'updated_at', label: 'Data de modificação', sortable: true }
      ],
      lista: [],
      selectMode: 'single',
      selecionados: [],
      paginaAtual: 1,
      total: 0,
      porPagina: 10,
      ordem: 'created_at',
      tp_ordem: true
    };
  },
  mounted() {
    this.obterLista(0);
  },
  methods: {
    ...mapActions('msgErro', { exibirMsgErro: 'exibirMsgErro' }),
    exibirFiltro() {
      this.filtrar = !this.filtrar;
    },
    definirOrdem(e) {
      this.ordem = e.sortBy;
      this.tp_ordem = e.sortDesc;
      this.obterLista(0);
    },
    obterLista(paginaAtual) {
      const pag = (paginaAtual !== undefined ? paginaAtual : this.paginaAtual) - 1;

      api.listar({
        offset: pag < 0 ? 0 : pag,
        limit: this.porPagina,
        ordem: this.ordem,
        tp_ordem: this.tp_ordem ? 'DESC' : 'ASC',
        name: this.filtro.nome,
        email: this.filtro.email,
        sale_status: this.filtro.status ? this.filtro.status.code : null,
      }).then(res => {
        this.lista = res.data.data;
        this.total = res.data.total;
      });
    },
    exportarXls() {
      api.exportarXls({
        ordem: this.ordem,
        tp_ordem: this.tp_ordem ? 'DESC' : 'ASC',
        name: this.filtro.nome,
        email: this.filtro.email,
        sale_status: this.filtro.status ? this.filtro.status.code : null,
      }).then(res => {
        var a = document.createElement("a"); //Create <a>
        a.href = res.data.file; //Image Base64 Goes here
        a.download = res.data.name; //File name Here
        a.click(); //Downloaded file
      });
    },
  }
};
</script>

<style>
</style>
