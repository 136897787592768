<template>
  <div>
      <div class="row">
        <div class="col-md-12">
          <h1>404 - Página não encontrada.</h1>
        </div>
      </div>
  </div>
</template>

<script>
export default {};
</script>
