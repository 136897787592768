const state = {
  exibirMsgErro: false,
  txtMsgErro: '',
};

const actions = {
  exibirMsgErro({ commit }, msg) {
    commit("definirMsg", msg && msg !== undefined ? msg : "Erro interno na aplicação");
    commit("trocarEstado", true);
  },
  reiniciar({ commit }) {
    commit("trocarEstado", false);
  }
};

const getters = {
  getMsgErro: state => {
    return state.exibirMsgErro;
  },
  getTxtMsgErro: state => {
    return state.txtMsgErro;
  }
};

const mutations = {
  trocarEstado(state, estado) {
    state.exibirMsgErro = estado;
  },
  definirMsg(state, msg) {
    state.txtMsgErro = msg;
  }
};

export const msgErro = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
