import Vue from "vue";
import Vuex from "vuex";
import { autenticacao } from "./modules/autenticacao";
import { loader } from "./modules/loader";
import { msgErro } from "./modules/msgErro";
import { msgSucesso } from "./modules/msgSucesso";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    autenticacao,
    loader,
    msgErro,
    msgSucesso
  }
});

export default store;
