<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <router-view></router-view>
    <loader></loader>
    <msg-erro></msg-erro>
    <msg-sucesso></msg-sucesso>
  </div>
</template>

<script>
  import Loader from './components/Loader.vue';
  import MsgErro from './components/MsgErro.vue';
  import MsgSucesso from './components/MsgSucesso.vue';

  export default {
      name: 'app',
      components: {
          Loader,
          MsgErro,
          MsgSucesso
      }
  };
</script>

<style>
  @import './assets/css/custom.css';
</style>

<style lang="scss">
.vue-notifyjs.notifications {
  .alert {
    z-index: 10000;
  }
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }
  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
<style>
.table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td {
    color: white !important;
    background-color: #467db9 !important;
}
.b-form-datepicker .form-control {
  border: 0 !important
}
</style>