<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/places" name="Locais" icon="ti-flag" />
        <sidebar-link to="/routes" name="Rotas" icon="ti-map" />
        <sidebar-link to="/tickets" name="Tickets" icon="ti-ticket" />
        <sidebar-link to="/agenda" name="Agenda" icon="ti-calendar" />
        <sidebar-link v-if="usuarioLogado.admin" to="/usuarios" name="Usuários" icon="ti-user" />
      </template>
      <mobile-menu>
        <li class="nav-item">
          <a @click="logout" class="nav-link">
            <i class="ti-power-off"></i>
            <p>Sair</p>
          </a>
        </li>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <page-content @click.native="toggleSidebar"></page-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import TopNavbar from "./TopNavbar.vue";
  import ContentFooter from "./ContentFooter.vue";
  import PageContent from "./Content.vue";
  import MobileMenu from "./MobileMenu";
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      PageContent,
      MobileMenu
    },
    data() {
      return {
        listaMenu: []
      }
    },
    async mounted() {
    },
    computed: {
      ...mapGetters('autenticacao', ['existUsuarioLogado','usuarioLogado'])
    },
    methods: {
      ...mapActions('autenticacao', {logout:'logout'}),
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>