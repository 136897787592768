import {AXIOS} from '@/http-common';

const base = '/api/user';

export default {
    listar : async function(dados) {     
        return await AXIOS.post(`${base}/list`, dados);
    },
    salvar : async function(dados) {     
        return await AXIOS.put(`${base}/save`, dados);
    },
    inativar : async function(id) {     
        return await AXIOS.get(`${base}/disable/${id}`);
    },
    ativar : async function(id) {     
        return await AXIOS.get(`${base}/enable/${id}`);
    },
    verificarSenha: async function(senha) {
        return await AXIOS.get(`${base}/checkPassword/${senha}`);
    }
}