<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
         <div>
            <div class="formLogin form">
               <div class="logo mb-3">
                  <div class="col-md-12 text-center">
                     <img src="@/assets/img/logo-jw.svg" alt="" height="80">
                     <h4>Sistema Transfer In Rio</h4>
                  </div>
               </div>

               <div>
                  <form @submit.prevent="submit">
                    <div class="form-group" :class="{ 'form-group--error': $v.email.$error }">
                      <label>E-mail</label>
                      <b-form-input
                          type="text"
                          class="form-control"
                          placeholder="E-mail"
                          maxlength="255"
                          v-model.trim="$v.email.$model"></b-form-input>
                    </div>
                    <div class="form-group" :class="{ 'form-group--error': $v.senha.$error }">
                      <label>Senha</label>
                      <b-form-input
                          type="password"
                          class="form-control"
                          placeholder="Senha"
                          maxlength="255"
                          v-model.trim="$v.senha.$model"></b-form-input>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="submit" class=" btn btn-block mybtn btn-primary tx-tfm">Acessar</button>
                      </div>
                    </div>
                  </form>
               </div>
            </div>
         </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import { required, email } from 'vuelidate/lib/validators';

export default {
  components: {
  },
  data() {
    return {
      email: '',
      senha: '',
      submitStatus: ''
    };
  },
  validations: {
    email: {
      required,
      email
    },
    senha: {
      required
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters('autenticacao', ['existUsuarioLogado','usuarioLogado'])
  },
  methods: {
    ...mapActions('autenticacao', {autenticacao:'autenticacao'}),
    ...mapActions('msgErro', {exibirMsgErro:'exibirMsgErro',}),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.exibirMsgErro("Erros no preenchimento do formulário.");
      } else {
        this.autenticar();
      }
    },
    async autenticar() {
      let retorno = await this.autenticacao({email: this.email, senha: this.senha});

      console.log("retorno", retorno);

      if(retorno){
        this.$router.push({name:'tickets'});
      }else {
        this.loginInvalido = true;
        this.exibirMsgErro("Acesso negado.");
      }
    }
  }
};
</script>
<style>
</style>